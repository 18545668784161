<template>
  <v-app>
    <v-spacer />
    <v-main>
      <v-spacer />
      <router-view />
      <v-spacer />
    </v-main>
    <v-spacer />
  </v-app>
</template>

<script>
export default {
  name: "DashboardCoreView",

  data: () => ({}),

  computed: {},
};
</script>